import { limitToFirst, limitToLast, onValue, orderByChild, query, ref } from 'firebase/database';
import React, { useContext, useEffect, useRef, useState } from 'react'
import { fbDb } from '../configs/firebaseConfig';
import Navbar from '../components/Navbar';
import { SendMessage } from '../apis/ChatApi';
import { AuthContext } from '../contexts/AuthContext';
import { formatDateTime, sortAscByKey, sortDescByKey } from '../helper/GlobalFunctions';
import NavbarMobile from '../components/NavbarMobile';

export const Chats = () => {
    const containerRef = useRef(null);
    const { user, } = useContext(AuthContext);
    const balai_warga_messages = query(ref(fbDb, 'balai_warga/messages/1'), orderByChild('sys_created_on'), limitToLast(100) );

    // data
    const [messages, set_messages] = useState([]);

    // FORM
    const [sender_message, set_sender_message] = useState('');
    const [is_loading_send, set_is_loading_send] = useState(false);

    const handleSend = async () => {
        if ( !sender_message ) {
            alert("Tidak mengirim pesan: tidak ada pesan apapun.")
            return false;
        }

        set_is_loading_send(true);
        const res = await SendMessage({
            sender_id: user.id,
            sender_name: user.name,
            sender_photo: false,
            sender_message: sender_message,
        });
        set_is_loading_send(false);

        if ( res == '200' ) {
            set_sender_message('');
            // alert("Berhasil mengirim pesan");
        } else {
            alert("Terjadi kesalahan pada server saat mengirim pesan");
        }
    }

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          // Handle the input value
          handleSend();
        }
      };

    useEffect( () => {
        console.log('user login', user);
        onValue( balai_warga_messages, (snapshot) => {
            const data = snapshot.val();

            console.log('data data', data)
            const dataArray = [];

            if ( data != null ) {
                Object.keys(data).forEach((key) => {
                    const newData = data[key];
                    newData.id = key
                    dataArray.push(newData);
                });
            }


            console.log('data', data);
            console.log('dataArray', dataArray);
            

            set_messages(sortAscByKey(dataArray, 'sys_created_on'));
            // Do something with the data
          } );
    }, [])

    useEffect(() => {

        // setTimeout(() => {
            const container = containerRef.current;
            if (container) {
                container.scrollTop = container.scrollHeight;
            } else {
                console.log(container)
                alert('Element not found');
            }
            
        // }, 1000);
    }, [messages]);
    

  return (
    <div>
        {/* <Navbar /> */}
        <div className='container-fluid'>
            <div className='row justify-content-center'>
                <div className='col-md-7'>
                    <NavbarMobile 
                        title={'Balai Warga'}
                    />
                    <div className='cardx card-body'>
                        {/* <div className='card-header bg-light text-dark d-flex'>
                            <button className='btn btn-sm btn-primary-outline me-3'>Kembali</button> <span className='fs-5'>Balai Warga Kintamani</span>
                        </div> */}
                        <div  ref={containerRef} className="card-bodyx" style={{ height: '75vh', overflowY: 'scroll'}}>
                            {
                                messages.length > 0 ?
                                messages.map((v,i) => {
                                    if ( v.sender_id == user.id ) {
                                        return (
                                            <div className='bubble-chat bg-primary text-light shadow p-3 rounded d-inline-blockx text-end mb-3'>
                                                <span className='bubble-content'>
                                                    {v.sender_message}
                                                </span>
                                                <div className='text-light'>
                                                    <small style={{ fontSize: 12}}>{ formatDateTime(v.sys_created_on)}</small>
                                                </div>
                                            </div>
                                        )
                                    }
                                    return(
                                        <div className='bubble-chat bg-light shadow p-3 rounded d-inline-blockx mb-3'>
                                            <div className='bubble-name' >
                                                <small className='fw-bold'>{v.sender_name}</small>
                                            </div>
                                            <span className='bubble-content'>{v.sender_message}</span>
                                            <div className='text-muted'>
                                                <small style={{ fontSize: 12}}>{ formatDateTime(v.sys_created_on)}</small>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <></>
                            }
                            
                        </div>
                        <div className='card-footer mt-3 row'>
                            <div style={{ width: '80%'}} >
                             <input className='form-control' value={sender_message} onChange={ (e) => set_sender_message(e.target.value) }  onKeyDown={handleKeyDown}/>
                            </div>
                            <div  style={{ width: '20%'}}>
                                <button disabled={is_loading_send} onClick={handleSend} className='btn btn-sm btn-md btn-primary w-100' >{ is_loading_send?'Loading' : <span className='fw-bold fs-5'> <i className='fa fa-paper-plane' /> </span>}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
