import React, { useState, useContext } from 'react';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { actionRegister } from '../apis/RegisterApi';
import { AuthContext } from '../contexts/AuthContext';

const RegisterPage = () => {
  const [loading_screen, set_loading_screen] = useState(false);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [password_confirmation, set_password_confirmation] = useState('');
  const [address, set_address] = useState('');
  const [rt_id, set_rt_id] = useState('');
  const [rw_id, set_rw_id] = useState('');
  const [error, setError] = useState('');
  const { login } = useContext(AuthContext);
  const nav = useNavigate();
  const [phase_register, set_phase_register] = useState(0);

  const handleChangeRT = (e) => {
    set_rt_id(e.target.value);
  }

  const handleChangeRW = (e) => {
    set_rw_id(e.target.value);
  }

  const validasi_register = () => {

    if ( phase_register == 0) {
      if ( !email ) {
        alert("Email address tidak boleh kosong");
        return '400';
      }
  
      if ( !password ) {
        alert("Password tidak boleh kosong");
        return '400';
      }
  
      if ( !password_confirmation ) {
        alert("Password konfirmasi tidak boleh kosong");
        return '400';
      }
  
      if ( password != password_confirmation ) {
        alert("Password dan Password konfirmasi tidak sama!. silahkan sesuaikan dahulu");
        return '400';
      }

    }

    if ( phase_register == 1) {
      if ( !name ) {
        alert("Nama tidak boleh kosong");
        return '400';
      }
  
      if ( !rt_id ) {
        alert("Silahkan pilih RT");
        return '400';
      }
  
      if ( !rw_id ) {
        alert("Silahkan pilih RW");
        return '400';
      }

      if ( !address ) {
        alert("Silahkan isi alamat");
        return '400';
      }
    }

  }

  const handleLanjut = () => {
    if ( validasi_register() == '400' ) {
      return false;
    }

    set_phase_register(phase_register+1);
    
  }

  const handleRegister = async () => {

    if ( validasi_register() == '400' ) {
      return false;
    }

    set_loading_screen(true);
    const res = await actionRegister({
      email: email,
      name: name,
      password: password,
      password_confirmation: password_confirmation,
      rt_id: rt_id,
      rw_id: rw_id,
      address: address,
    }) ;
    set_loading_screen(false);

    if ( res.code === '200') {
      alert(res.message);
      window.location.reload();
    } else {
      alert(res.message);
    }

    console.log("llogin res", res);
  };

  return (
    <div className="container" style={{
      marginTop: '10vh'
    }}>
      <div className='row justify-content-center'>
        <div className='col-md-5'>
        <h1 className="mb-4">Pendaftaran Warga Online</h1>
          <form onSubmit={handleRegister}>
            {
              phase_register == 0 ?
                <>
                  <label className='text-muted'>Form pendaftaran akun untuk menggunakan aplikasi warga online</label>
                  <div className="mb-3 mt-3">
                    <label htmlFor="email" className="form-label">Email</label>
                    <input required type="email" id="email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Alamat email' />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input required type="password" id="password" className="form-control" value={password} onChange={(e) => setPassword(e.target.value)} placeholder='Password' />
                  </div>
                  <div className="mb-3">
                    <label htmlFor="password" className="form-label">Password Konfimasi</label>
                    <input required type="password" id="password_konfimasi" className="form-control" value={password_confirmation} onChange={(e) => set_password_confirmation(e.target.value)} placeholder='Konfimasi Password' />
                  </div>
                  <div className='row justify-content-between'>
                    <div className='col'>

                    </div>
                    <div className='col text-end'>
                      <button type="button" className="btn btn-primary mb-3" onClick={ handleLanjut }>Lanjut</button>
                    </div>
                  </div>
                </>
              :
                <>
                  <label className='text-muted'>Infomasi untuk profil kamu setelah masuk aplikasi</label>
                  <div className="mb-3 mt-3">
                    <label htmlFor="name" className="form-label">Nama lengkap</label>
                    <input required type="text" id="name" className="form-control" value={name} onChange={(e) => setName(e.target.value)} placeholder='Nama lengkap' />
                  </div>
                  <div className="mb-3 mt-3">
                    <div className='row'>
                      <div className='col'>
                        <label htmlFor="name" className="form-label">RT</label>
                        <select className='form-control' onChange={handleChangeRT}>
                          <option>= Pilih RT =</option>
                          <option value={1}>001</option>
                        </select>
                      </div>
                      <div className='col'>
                        <label htmlFor="name" className="form-label">RW</label>
                        <select className='form-control' onChange={handleChangeRW}>
                          <option>= Pilih RW =</option>
                          <option value={2}>008</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="mb-3 mt-3">
                    <label htmlFor="name" className="form-label">Alamat</label>
                    <textarea 
                      className='form-control'
                      placeholder='Alamat'
                      value={address} onChange={(e) => set_address(e.target.value)}
                    />
                  </div>
                  <div className='row justify-content-between'>
                    <div className='col'>
                      <button type="button" className="btn btn-primary-outline mb-3" onClick={ () => set_phase_register(phase_register-1)}>Kembali</button>
                    </div>
                    <div className='col text-end'>
                      <button type="button" className="btn btn-primary mb-3" onClick={handleRegister}>{loading_screen?'Loading':'Daftar'}</button>
                    </div>
                  </div>
                </>
            }
            {error && <p>{error}</p>}
          </form>
          <p>Sudah punya akun? <Link to="/login">Masuk disini</Link>.</p>
        </div>
      </div>
    </div>
  );
};

export default RegisterPage;
