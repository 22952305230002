export function getTimestamp() {
    const now = new Date();
    const timestamp = now.getTime();
    return timestamp;
}

// Fungsi untuk mengkonversi timestamp menjadi tanggal saja
export function formatDate(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const formattedDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    return formattedDate;
}
  
  // Fungsi untuk mengkonversi timestamp menjadi tanggal dengan jam
export function formatDateTime(timestamp) {
    const now_date = new Date();
    const now_year = now_date.getFullYear();
    const now_month = now_date.getMonth() + 1;
    const now_day = now_date.getDate();

    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const seconds = date.getSeconds();
    var formattedDateTime = '';

    if ( now_year == year && now_month == month && now_day == day) {
        formattedDateTime = `Hari ini ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}`;

    } else {
        formattedDateTime = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day} ${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`;
    }

    return formattedDateTime;
}
  
// Fungsi untuk mengurutkan data berdasarkan nilai kunci dalam urutan menurun (descending order)
export function sortDescByKey(data, key) {
    return data.sort((a, b) => b[key] - a[key]);
}
  
export function sortAscByKey(data, key) {
    return data.sort((a, b) => a[key] - b[key]);
  }

// Contoh penggunaan
// const sortedData = sortDescByKey(data, 'score');