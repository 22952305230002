import Header from '../components/Home/Header'
import Fitur from '../components/Home/Fitur'
import Kegiatan from '../components/Home/Kegiatan'
import Berita from '../components/Home/Berita'
import useWindowDimensions from '../hooks/useWindowDimensions'
import { useEffect } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'


export default function Home() {
  const { height, width } = useWindowDimensions()

  useEffect( () => {

  }, [])

  return (
    <>
      <Navbar />
      <Header />
      <Fitur />
      <Kegiatan />
      <Berita />
      <Footer />
    </>
  )
}
