import React, { useContext, useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import Layout from '../components/layout'
import { AuthContext } from '../contexts/AuthContext'
import Home from '../pages/Home'
import PageNyasar from '../pages/PageNyasar'
import Profile from '../pages/Profile'
import { PublicRoutes } from './PublicRoutes'
import { Chats } from '../pages/Chats'

export const PrivateRoutes = () => {
    const { user } = useContext(AuthContext);
    const nav = useNavigate();

    useEffect( () => {
        if ( !user ) {
            nav('/login');
        }
    }, [])

  return (
    <>
        <Layout>
            <Routes>
                <Route path='/' element={ <Home /> } />
                <Route path='/profile' element={ <Profile /> } />
                <Route path='/chats' element={ <Chats /> } />
                <Route path='*' element={ <PageNyasar /> } />
            </Routes>
        </Layout>
    </>
  )
}
