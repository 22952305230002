import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

export default function PageNyasar() {
    const { user } = useContext(AuthContext)
  return (
    <div className='container mt-5'>
        <div className='row justify-content-center mt-5'>
            {
                user?
                <div className='col-md-4 mt-5 text-center'>
                    <h1>404 Not Found</h1>
                    <Link to="/profile">Go back to profile</Link>
                </div>
                :
                <div className='col-md-4 mt-5 text-center'>
                    <h1>404 Not Found</h1>
                    <p>The page you're looking for does not exist.</p>
                    <Link to="/">Go back to home</Link>
                </div>
            }
        </div>
    </div>
  )
}
