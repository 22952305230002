import React from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions'
import BottomTab from './BottomTab';
import Footer from './Footer'
import Navbar from './Navbar'

export default function Layout({children}) {
  const { height, width } = useWindowDimensions();

  if ( width > 991 ) {
    return (
      <>
          {/* <Navbar /> */}
          <main>{ children }</main>
          {/* <Footer /> */}
      </>
    )
  }

  if ( width < 991 ) {
    return (
      <>
          {/* <BottomTab /> */}
          <main>{ children }</main>
      </>
    )
  }

}
