import React, { useContext } from 'react'
import { Link } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import useLastLocation from '../hooks/useLastLocation';
import useWindowDimensions from '../hooks/useWindowDimensions';
import Footer from './Footer';

const OffCanvas = ({ logout, width }) => {
    const { isAuthenticated } = useContext(AuthContext);
    const handleLogout = () => {
        logout();
        window.location.href = "/login";
    }

    const handleLink = (url_string) => {
        window.location.href = url_string;
    }
    return (
        <div className={`offcanvas offcanvas-end ${width > 991?'':'w-75'}`} tabindex="-1" id="offcanvasMore" aria-labelledby="offcanvasMoreLabel">
            <div className="offcanvas-header">
                <h5 className="offcanvas-title" id="offcanvasMoreLabel">Menu Lain</h5>
                <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body">
                <ul className="list-group">
                    {
                        isAuthenticated()?
                            <>  
                                <li type="button" className="list-group-item " onClick={ () => handleLink('/chats') }>
                                    Balai Warga
                                </li>
                                <li type="button" className="list-group-item " onClick={ () => handleLink('/profile') }>
                                    Profil
                                </li>
                                <li type="button" className="list-group-item " onClick={handleLogout}>Logout</li>
                            </>
                        :
                            <>
                                <li type="button" className="list-group-item " onClick={ () => handleLink('/login') }>
                                    <Link to='login' >Login</Link>
                                </li>
                            </>
                    }
                </ul>
                 <Footer />
            </div>
        </div>
    )
}

export default function Navbar() {
    const { user, isAuthenticated, logout } = useContext(AuthContext);
    const lastLocation = useLastLocation();
    const { height, width } = useWindowDimensions();

    if ( width > 991 ) {
        return (
            <>
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    <div className='container'>
                        <a className="navbar-brand" href="/">Warga</a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav me-auto">
                                <li className="nav-item active">
                                    <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Kegiatan</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#">Berita</a>
                                </li>
                            </ul>
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a class="nav-link" data-bs-toggle="offcanvas" href="#offcanvasMore" role="button" aria-controls="offcanvasMore">
                                        Menu Lain
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
        
                <OffCanvas logout={logout} width={width} />
            </>
        )
    }

    if ( width < 991 ) {
        return (
            <>
                <nav className="navbar fixed-bottom navbar-light bg-light">
                    <div className="container-fluid">
                        <ul className="navbar-nav bottom-tabx">
                        <li className="nav-item bottom-tabx">
                            <a className={`nav-link bottom-tabx ${lastLocation=='/'?'active':''}`} aria-current="page" href="/">
                                <i class="fa-solid fa-house"></i> <br/>
                                <span>Home </span>
                            </a>
                        </li>
                        <li className="nav-item bottom-tabx">
                            <a className={`nav-link bottom-tabx ${lastLocation=='/kegiatan'?'active':''}`} href="#">
                                <i class="fa-solid fa-people-roof"></i>  <br/>
                                <span>Kegiatan</span>
                            </a>
                        </li>
                        <li className="nav-item bottom-tabx">
                            <a className={`nav-link bottom-tabx ${lastLocation=='/berita'?'active':''}`} href="#">
                                <i class="fa-solid fa-newspaper"></i> <br/>
                                <span>Berita</span>
                            </a>
                        </li>
                        {
                            isAuthenticated()?
                            <>
                                <li className="nav-item bottom-tabx">
                                    <Link to='/profile' className={`nav-link bottom-tabx ${lastLocation=='/profile'?'active':''}`}>
                                            <i class="fa-solid fa-user"></i> <br/>
                                            <span>Profil</span>
                                    </Link>
                                </li>
                                <li className="nav-item bottom-tabx">
                                    <a  className={`nav-link bottom-tabx  ${lastLocation=='/profile'?'active':''}`} data-bs-toggle="offcanvas" href="#offcanvasMore" role="button" aria-controls="offcanvasMore">
                                            <i class="fa-solid fa-bars"></i> <br/>
                                            <span>Menu Lain</span>
                                    </a>
                                </li>
                            </>
                            :
                            <>
                                <li className="nav-item bottom-tabx">
                                    <a  className={`nav-link bottom-tabx  ${lastLocation=='/profile'?'active':''}`} data-bs-toggle="offcanvas" href="#offcanvasMore" role="button" aria-controls="offcanvasMore">
                                            <i class="fa-solid fa-bars"></i> <br/>
                                            <span>Menu Lain</span>
                                    </a>
                                </li>
                            </>
                        }
                        
                        </ul>
                    </div>
                </nav>
        
                <OffCanvas logout={logout} width={width} />
            </>
          )
    }



}
