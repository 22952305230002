import React from 'react'

export default function Header() {
  return (
    <>
        <header className="bg-image my-5">
            <div className="container">
                <div className="row align-items-center">
                <div className="col-md-6">
                    <h1>Aplikasi Warga Online</h1>
                    <p>Manfaatkan teknologi untuk memudahkan komunikasi dan koordinasi antar warga desa.</p>
                    <a href="/register" className="btn btn-outline-primary mb-3">Daftar Sekarang</a>
                    <span className='mx-3 fs-1'>/</span>
                    <a href="/login" className="btn btn-primary mb-3">Masuk</a>
                </div>
                <div className="col-md-6">
                    <img src="https://source.unsplash.com/random/600x400?technology" alt="Aplikasi Warga Desa" className="img-fluid" />
                </div>
                </div>
            </div>
        </header>
    </>
  )
}
