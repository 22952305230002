import React, { useContext, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Layout from './components/layout';
import { PublicRoutes } from './routes/PublicRoutes';
import { PrivateRoutes } from './routes/PrivateRoutes';
import { AuthContext, AuthProvider } from './contexts/AuthContext';
import { GuestRoute } from './routes/GuestRoute';
import PageNyasar from './pages/PageNyasar';

function App() {
  const { user } = useContext(AuthContext);
  return (
    <>
        <BrowserRouter>
          <Routes>
            {
              user?
                <Route path='/*' element={ <PrivateRoutes /> } />
              :
                <>
                  <Route path='/*' element={ <PublicRoutes /> } />
                </>
            }
          </Routes>
        </BrowserRouter>
    </>
  );
}

export default App;
