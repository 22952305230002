import React from 'react'

export default function Berita() {
  return (
    <>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center mt-5">
                <h1>Berita Desa</h1>
                <p className="lead">Berikut adalah beberapa berita terbaru dari desa kami.</p>
                </div>
            </div>
            <div className="row mt-5">
                <div className="col-md-4">
                <div className="card mt-3">
                    <img src="https://source.unsplash.com/random/400x300?news,1" className="card-img-top" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title text-ellipsis">Warga RT 01 Bersihkan Lingkungan Sebagai Upaya Pencegahan Penyebaran Covid-19</h5>
                    <p className="card-text text-ellipsis ">Warga RT 01 berpartisipasi dalam kegiatan kerja bakti untuk membersihkan lingkungan mereka pada Sabtu, 20 Maret 2023. Kegiatan ini dilakukan sebagai bagian dari upaya pencegahan penyebaran Covid-19 di desa tersebut.</p>
                    <a href="#" className="btn btn-primary">Baca Selengkapnya</a>
                    </div>
                </div>
                </div>
                <div className="col-md-4">
                <div className="card mt-3">
                    <img src="https://source.unsplash.com/random/400x300?news,2" className="card-img-top" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title text-ellipsis">Warga RT 01 ABC Mengadakan Lomba Memasak Tradisional</h5>
                    <p className="card-text text-ellipsis">Warga RT 01 mengadakan lomba memasak tradisional pada Minggu, 21 Maret 2023. Lomba ini diikuti oleh lebih dari 20 peserta dari berbagai kelompok masyarakat di desa tersebut.</p>
                    <a href="#" className="btn btn-primary">Baca Selengkapnya</a>
                    </div>
                </div>
                </div>
                <div className="col-md-4">
                <div className="card mt-3">
                    <img src="https://source.unsplash.com/random/400x300?news,3" className="card-img-top" alt="..." />
                    <div className="card-body">
                    <h5 className="card-title text-ellipsis">Warga RT 01 Berhasil Menerapkan Teknologi Hijau untuk Pengolahan Sampah</h5>
                    <p className="card-text text-ellipsis">Warga RT 01 berhasil menerapkan teknologi hijau untuk pengolahan sampah. Teknologi ini menggunakan proses fermentasi yang ramah lingkungan dan menghasilkan pupuk organik yang dapat digunakan untuk pertanian.</p>
                    <a href="#" className="btn btn-primary">Baca Selengkapnya</a>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </>
  )
}
