import { query, ref, set } from "firebase/database";
import { fbDb } from "../configs/firebaseConfig";
import { getTimestamp } from "../helper/GlobalFunctions";
import { v4 as uuidv4 } from 'uuid';

export const SendMessage = (props) => {
    const uuid = uuidv4();
    const timestamp = getTimestamp();

    const messageRef = query(ref(fbDb, 'balai_warga/messages/1/'+uuid), );

    try {
        
        set(messageRef, {
            sender_id: props.sender_id,
            sender_name: props.sender_name,
            sender_photo: props.sender_photo,
            sender_message: props.sender_message,
            sys_created_on: timestamp,
        })

        return '200'
    } catch (error) {
        return '500';
    }

}