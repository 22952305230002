import React from 'react'

export default function Fitur() {
  return (
    <>
        <section className="features my-5">
            <div className="container">
                <div className="row">
                <div className="col-md-4">
                    <div className="feature">
                    <i className="fa fa-comments" />
                    <h3>Komunikasi</h3>
                    <p>Komunikasi antar warga desa menjadi lebih mudah dan efektif dengan fitur pesan instan.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="feature">
                    <i className="fa fa-tasks" />
                    <h3>Koordinasi</h3>
                    <p>Koordinasi kegiatan desa menjadi lebih terstruktur dengan fitur pengelolaan agenda dan tugas.</p>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="feature">
                    <i className="fa fa-newspaper" />
                    <h3>Informasi</h3>
                    <p>Dapatkan informasi terbaru tentang kegiatan dan berita desa dengan fitur notifikasi dan feed berita.</p>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </>
  )
}
