import React, { useContext, useEffect, useState } from 'react'
import Navbar from '../components/Navbar';
import { AuthContext } from '../contexts/AuthContext';
import Footer from '../components/Footer';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getDatabase, ref, query, orderByChild, limitToLast, onValue } from "firebase/database";
import { fbDb } from '../configs/firebaseConfig';

export default function Profile() {
    const { isAuthenticated } = useContext(AuthContext);
    

    const [isEditMode, setIsEditMode] = useState(false);
    const [user, setUser] = useState({
      photoUrl: 'https://via.placeholder.com/150',
      nik: '1234567890123456',
      nama: 'John Doe',
      email: 'johndoe@example.com',
      provinsi: 'Jawa Tengah',
      kota: 'Semarang',
      kecamatan: 'Tembalang',
      alamat: 'Jl. Setiabudi No. 10'
    });

    const balai_warga_messages = query(ref(fbDb, 'balai_warga/messages/1'), );
    const mostViewedPosts = query(ref(fbDb, 'posts'), orderByChild('metrics/views'), limitToLast(2));
    const recentPostsRef = query(ref(fbDb, 'posts'), limitToLast(1));


    useEffect( () => {
        retriveSession();
        console.log('recentPostsRef', recentPostsRef);

        onValue( balai_warga_messages, (snapshot) => {
            const data = snapshot.val();

            const dataArray = [];

            Object.keys(data).forEach((key) => {
                const newData = data[key];
                newData.id = key
                dataArray.push(newData);
            });
            console.log('data', data);
            console.log('dataArray', dataArray);
            // Do something with the data
          } );
    }, []) 

    const retriveSession = () => {
        const userString = localStorage.getItem('user');
        const userJSON = JSON.parse(userString);
        setUser({
            photoUrl: 'https://via.placeholder.com/150',
            nik: '1234567890123456',
            nama: userJSON.name,
            email: userJSON.email,
            provinsi: userJSON.province,
            kota: userJSON.city,
            kecamatan: userJSON.district,
            rt_name: userJSON.rt_name,
            rw_name: userJSON.rw_name,
            alamat: userJSON.address,
        })
    }
  
    const handleEditClick = () => {
      setIsEditMode(true);
    };
  
    const handleSaveClick = () => {
      setIsEditMode(false);
      // save profile changes to server
    };
  
    const handleCancelClick = () => {
      setIsEditMode(false);
      // reset profile form
    };
  
    const handleInputChange = (e) => {
      setUser({
        ...user,
        [e.target.name]: e.target.value
      });
    };

    function clearCache() {
        console.log('caches' in window);
        if ('caches' in window) {
          caches.keys().then(function(cacheNames) {
                console.log('clear cache 1', cacheNames)
                cacheNames.forEach(function(cacheName) {
                console.log('clear cache')
              caches.delete(cacheName);
            });
          });
        }
    }

    // const notify = () => toast("Wow so easy!");
    const notify = () => {
        toast.info(`Update available! To update, close all windows and reopen.`, {                toastId: "appUpdateAvailable",
            // Prevent duplicate toasts                
            // onClick: () => window.close(), 
            onClick: () => clearCache(), 
            // Closes windows on click                
            autoClose: false 
            // Prevents toast from auto closing             
        });
    }
  
    const renderProfile = () => {
        return (
            <>
                <Navbar/>
                <div className='container mt-3' style={{ minHeight: '100vh'}}>
                    <div className='row justify-content-center'>
                        <div className='col-md-8'>
                            <div className="cardx">
                                {/* <div className="card-header d-flex justify-content-between align-items-center">
                                <h5 className="m-0">Profile</h5>
                                <button className="btn btn-primary" onClick={handleEditClick}>
                                    Edit
                                </button>
                                </div> */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-3 d-flex-sm justify-content-center">
                                        <img src={user.photoUrl} alt="Profile" className="img-fluid mb-3 rounded-circle" />
                                        </div>
                                        <div className="col-md-9">
                                            <p>
                                                <strong>NIK:</strong> {user.nik}
                                            </p>
                                            <p>
                                                <strong>Nama:</strong> {user.nama}
                                            </p>
                                            <p>
                                                <strong>Email:</strong> {user.email}
                                            </p>
                                            <p>
                                                <strong>RT:</strong> {user.rt_name}
                                            </p>
                                            <p>
                                                <strong>RW:</strong> {user.rw_name}
                                            </p>
                                            {/* <p>
                                                <strong>Provinsi:</strong> {user.provinsi}
                                            </p>
                                            <p>
                                                <strong>Kota:</strong> {user.kota}
                                            </p>
                                            <p>
                                                <strong>Kecamatan:</strong> {user.kecamatan}
                                            </p> */}
                                            <p>
                                                <strong>Alamat:</strong> {user.alamat}
                                            </p>
                                        </div>
                                        {/* <div>
                                            <button onClick={notify}>Update!</button>
                                            <ToastContainer />
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
      };
  
    const renderProfileForm = () => {
        return (
            <div>
            <div>
                <img src={user.photoUrl} alt="Profile" />
                <input type="file" />
            </div>
            <div>
                <p>
                NIK: <input type="text" name="nik" value={user.nik} onChange={handleInputChange} />
                </p>
                <p>
                Nama: <input type="text" name="nama" value={user.nama} onChange={handleInputChange} />
                </p>
                <p>
                Email: <input type="email" name="email" value={user.email} onChange={handleInputChange} />
                </p>
                <p>
                Provinsi: <input type="text" name="provinsi" value={user.provinsi} onChange={handleInputChange} />
                </p>
                <p>
                Kota: <input type="text" name="kota" value={user.kota} onChange={handleInputChange} />
                </p>
                <p>
                kecamatan: <input type="text" name="kecamatan" value={user.kecamatan} onChange={handleInputChange} />
                </p>
                <p>
                Alamat: <textarea name="alamat" value={user.alamat} onChange={handleInputChange}></textarea>
                </p>
            </div>
            <div>
                <button onClick={handleSaveClick}>Save</button>
                <button onClick={handleCancelClick}>Cancel</button>
            </div>
            </div>
        )
    }

    return (
    <div>
    {isEditMode ? renderProfileForm() : renderProfile()}
        <Footer />
    </div>
    );
}
