import React, { createContext, useEffect, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect( () => {
        const userString = localStorage.getItem('user');
        if ( userString ) {
          const userJSON = JSON.parse(userString);
            setUser(userJSON)
        }
  }, [])

  const login = (userCredentials) => {
    localStorage.setItem('user', JSON.stringify(userCredentials));
    // Login logic
    setUser(userCredentials);
  };

  const logout = () => {
    // Logout logic
    localStorage.clear();
    setUser(null);
  };

  const isAuthenticated = () => {
    // Authentication logic
    return !!user;
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};
