import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory, useNavigate } from 'react-router-dom';
import { actionLogin } from '../apis/LoginApi';
import { AuthContext } from '../contexts/AuthContext';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [show_password, set_show_password] = useState(false)
  const [error, setError] = useState('');
  const { isAuthenticated, login } = useContext(AuthContext);
  const nav = useNavigate();
  const [loading_screen, set_loading_screen] = useState(false);

    useEffect( () => {

    }, [])

    const handleShowPassword = () => {
      set_show_password(!show_password)
    }

  const handleLogin = async () => {
    set_loading_screen(true);
    const res = await actionLogin({
      email: email,
      password: password,
    }) ;
    set_loading_screen(false);

    if ( res.code === '200') {
      login({
        email,
        id: res.user.id,
        name: res.user.name,
        province: 'JAWA BARAT',
        city: 'BEKASI',
        district: 'TAMBUN UTARA',
        rt_name: res.user_details?res.user_details[0].rt_name:'',
        rw_name: res.user_details?res.user_details[0].rw_name:'',
        address: res.user_details?res.user_details[0].address:'',
        token: res.token,
      });
      nav('/profile');
    } else {
      alert(res.message);
    }

    console.log("llogin res", res);
  };

  return (
    <div className="container" style={{
      marginTop: '20vh'
    }}>
      <div className='row justify-content-center'>
        <div  className='col-md-5'>
          <h1 className="mb-4">Masuk Warga Online</h1>
          <form className='shadow p-3 mb-5 bg-white rounded' >
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" className="form-control" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input type={show_password?"text":"password"} className="form-control" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <div className="form-check my-3" onClick={handleShowPassword}>
              <input
                className="form-check-input"
                type="checkbox"
                id="flexCheckChecked"
                checked={show_password}
                onChange={handleShowPassword}
              />
              <label className="form-check-label" htmlFor="flexCheckChecked" >
                {
                  show_password?
                  'Hide password'
                  : 'Show Password'
                }
              </label>
            </div>


            <button type="button" className="btn btn-primary" onClick={handleLogin} disabled={ loading_screen }>{loading_screen?'Loading':'Login'}</button>
            {error && <p className="text-danger">{error}</p>}
          </form>
          <p className="mt-3">Kamu tidak punya akun? <Link to="/register" className='text-primary'>Daftar disini</Link>.</p>
          <p className="mt-3">Kembali ke  <Link to="/" className='text-primary'>Halaman Utama</Link>.</p>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
