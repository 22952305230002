import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Layout from '../components/layout'
import Home from '../pages/Home'
import LoginPage from '../pages/Login'
import PageNyasar from '../pages/PageNyasar'
import RegisterPage from '../pages/Register'

export const PublicRoutes = () => {
  return (
    <>
        <Layout>
            <Routes>
                <Route path='/' element={ <Home /> } />
                <Route path='/login' element={ <LoginPage /> } />
                <Route path='/register' element={ <RegisterPage /> } />
                <Route path='*' element={ <PageNyasar /> } />
            </Routes>
        </Layout>
    </>
  )
}
