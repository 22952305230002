import React from 'react'
import styles from '../styles/Home.module.css'
import { versi_aplikasi } from '../helper/Constants'

export default function Footer() {
  return (
    <>
      <div className='container mt-5'>
        <div className='row'>
          <div className='col-md-12 text-center'>
              <footer>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <small>
                Powered by{' '} 
                Karang Taruna Kintamani 
                {/* RT 01 / RW 08 Perum. Taman Kintamani, Desa Jejalen Jaya, Tambun Utara, Bekasi.   */}
                </small>
              </a>
              <br/>
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <small>
                  Copyright@{ new Date().getFullYear()} | Versi: { versi_aplikasi }
                </small>
              </a>
            </footer>
          </div>
        </div>
      </div>
      
    </>
  )
}
