import React from 'react'
import axios from 'axios';
import { url_api_server } from '../helper/Constants';

export const actionLogin = async (objData) => {
    try {
        const res = await axios({
            method: 'post',
            url: url_api_server+'api/login',
            data: {
              email: objData.email,
              password: objData.password
            }
        });

        return res.data;

    } catch (error) {
        return {
            code: '666',
            message: error
        };
    }
}
