import React from 'react'
import axios from 'axios';
import { url_api_server } from '../helper/Constants';

export const actionRegister = async (objData) => {
    try {
        const res = await axios({
            method: 'post',
            url: url_api_server+'api/register',
            data: {
              name: objData.name,
              email: objData.email,
              password: objData.password,
              password_confirmation: objData.password_confirmation,
              rt_id: objData.rt_id,
              rw_id: objData.rw_id,
              address: objData.address,
            }
        });

        return res.data;

    } catch (error) {
        return {
            code: '666',
            message: error
        };
    }
}
