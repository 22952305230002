import React from 'react'
import useWindowDimensions from '../hooks/useWindowDimensions';
import { useNavigate } from 'react-router-dom';

export default function NavbarMobile({title}) {
    const { height, width } = useWindowDimensions();
    const nav = useNavigate();

    const handleKembali = () => {
        nav(-1);
    }

    if ( width > 0 ) {
        return (
          <div>
              <nav className="navbar bg-body-tertiary">
                  <div className="container-fluid">
                        <div className="navbar-brand" onClick={handleKembali}>
                            
                            <span className='fs-6'> <i className="fa fa-arrow-left" /> &nbsp;Kembali</span>
                        </div>
                      <a className="navbar-brand" href="#">
                       {title}
                      </a>
                  </div>
              </nav>
      
          </div>
        )
    }

}
