import React from 'react'

export default function Kegiatan() {
  return (
    <>
        <div className="container">
            <div className="row">
            <div className="col-md-12 text-center mt-5">
                <h1>Kegiatan Warga Desa</h1>
                <p className="lead">Berikut adalah beberapa kegiatan yang dilakukan oleh warga desa kami.</p>
            </div>
            </div>
            <div className="row mt-5">
            <div className="col-md-4">
                <div className="card mt-3">
                <img src="https://source.unsplash.com/random/400x300?work,1" className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">Kerja Bakti</h5>
                    <p className="card-text">Warga desa kami melakukan kerja bakti secara bergotong royong untuk membersihkan lingkungan.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card mt-3">
                <img src="https://source.unsplash.com/random/400x300?ondel" className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">Kegiatan Seni Budaya</h5>
                    <p className="card-text">Warga desa kami melakukan kegiatan seni budaya untuk melestarikan tradisi dan budaya daerah.</p>
                </div>
                </div>
            </div>
            <div className="col-md-4">
                <div className="card mt-3">
                <img src="https://source.unsplash.com/random/400x300?futsal" className="card-img-top" alt="..." />
                <div className="card-body">
                    <h5 className="card-title">Kegiatan Olahraga</h5>
                    <p className="card-text">Warga desa kami melakukan kegiatan olahraga seperti futsal, voli, dan badminton untuk menjaga kesehatan dan kebugaran tubuh.</p>
                </div>
                </div>
            </div>
            </div>
        </div>
    </>
  )
}
